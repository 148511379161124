import React from 'react'
import './Landing.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Landing = () => {
    return (
        <>
            <div className="landing-cover">
                <div className="landing-overlay" />
                <div class="container">
                    <div class="row">
                        <div class="col col-12">
                            <p className="text-white display-3 landing-title mb-3">Jergon Aviation Consultancy</p>
                            <p className="text-white landing-main mb-0"><b>Jeremy "Jeng" Gonzales</b></p>
                            <p className="text-white landing-sub mb-3"><i>Aviation Technical Consultant</i></p>
                        </div>
                    </div>
                </div>
                <div class="scroll"></div>
            </div>
            <div className="bg-white pt-4 pb-4">
                <div class="container">
                    <div class="row">
                        <div class="col col-12">
                            <h2 className="text-center text-primary my-4 experience-lead"><b>With 20 years experience in Asset Management within aviation industry. Experienced in Technical and Commercial Fleet Management for Powerplant and Aircraft Retirement and Deliveries with a demonstrated history of working in the Airlines and Engine MRO. Skilled in Physical Inspection and Documents Evaluation.</b></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-5 px-3 text-center services-cover">
                <h1 className="text-white">Services</h1>
                <p className="text-white lead landing-text mb-5">Delivers customer satisfaction services</p>
                <div class="container">
                    <div class="row">
                        <div class="col col-lg-6 col-12">
                            <ul className="services-list">
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Aircraft Pre Purchase Inspection
                                </li>
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Aircraft Midterm Inspection
                                </li>
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Aircraft End of Lease Management
                                </li>
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Heavy Maintenance Check Management
                                </li>
                            </ul>
                        </div>
                        <div class="col col-lg-6 col-12">
                            <ul className="services-list">
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Engine Shop Visit Management
                                    <ul className="ml-4 mt-2">
                                        <li>Table Inspection</li>
                                        <li>Shop Visit Forecasting</li>
                                        <li>Powerplant Contract</li>
                                        <li>Cost Evaluation</li>
                                    </ul>
                                </li>
                                <li className="mb-3 text-white services-list-li">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-3 text-danger"
                                    />
                                    Engine Performance Evaluation
                                    <ul className="ml-4 mt-2">
                                        <li>BSI Evaluation</li>
                                        <li>Engine Trend Evaluation</li>
                                        <li>MPA Evaluation</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary py-5 px-3 text-center">
                <h1 className="text-white">Contact Us</h1>
                <p className="text-white lead landing-text mb-5">Let's have a talk</p>
                <div class="container">
                    <div class="row">
                        <div class="col col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="mr-3 text-white"
                                size="lg"
                            />
                            <p className="text-white lead mb-0 contact-us-text">jerremy.gonzales@jergonavcon.com</p>
                        </div>
                        <div class="col col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                            <FontAwesomeIcon
                                icon={faPhone}
                                className="mr-3 text-white"
                                size="lg"
                            />
                            <p className="text-white lead mb-0 contact-us-text">(+63) 9178052023</p>
                        </div>
                        <div class="col col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                            <FontAwesomeIcon
                                icon={faLinkedin}
                                className="mr-3 text-white"
                                size="lg"
                            />
                            <a
                                class="btn btn-link text-white lead mb-0 contact-us-text"
                                href="https://www.linkedin.com/in/jerremy-gonzales-306a5041/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Jeremy "Jeng" Gonzales
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-dark py-2 px-3">
                <p className="text-white mb-0 copyright-text">Copyright &#169; 2021 Jergonavcon. All rights reserved.</p>
            </div>
        </>
    )
}

export default Landing