import React from 'react'
import Routes from 'routes'
// import Header from 'Layouts/Header'

// import Header from '@/Layouts/Header'
// import Footer from '@/Layouts/Footer'
// import Store from '@/Contexts/Store'

const App = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes />
      {/* <Footer /> */}
    </>
  )
}

export default App;
